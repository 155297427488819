<template>
  <b-overlay :show="isLoading">
    <div id="pdf-container">
      <div class="page">
        <div v-for="shift in shifts" :key="shift.layerId">
          <h2>{{ shift.layerName }} - {{ shift.shiftName }}</h2>

          <table>
            <tr>
              <th>{{ $t('ManageShifts.EmployeeNumber') }}</th>
              <th>{{ $t('ManageShifts.Name') }}</th>
              <th>{{ $t('ManageShifts.ServiceProvider') }}</th>
            </tr>

            <tr
              v-for="context in shift.employees"
              :key="context.staffScheduling.id"
            >
              <td>{{ getEmployeeNumber(context) }}</td>
              <td>{{ getName(context) }}</td>
              <td>{{ getServiceProvider(context) }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="controls">
        <b-button
          variant="outline-secondary"
          class="d-print-none"
          @click="$router.push({ name: 'manage-shifts' })"
        >
          {{ $t('Global.Retreat') }}
        </b-button>

        <b-button variant="primary" class="d-print-none" @click="print()">
          {{ $t('Global.Print') }}
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BButton } from 'bootstrap-vue'
import { StaffSchedulingType } from '../staff-scheduling/enums'

export default {
  name: 'PrintMangeShifts',

  components: {
    BOverlay,
    BButton,
  },

  data: () => ({
    shifts: [],

    isLoading: false,
  }),

  async mounted() {
    try {
      this.isLoading = true

      const { data } = await this.$axios.get(
        'manage-shifts/my-current-responsibilities',
      )

      this.shifts = data.filter(x => x.employees.length > 0)
      this.isLoading = false

      await new Promise(resolve => setTimeout(resolve, 750))

      this.print()
    } catch (error) {
      console.error(error)
      this.$alert.error()
      this.isLoading = false
    }
  },

  methods: {
    getEmployeeNumber(context) {
      if (context.staffScheduling.type === StaffSchedulingType.Employee) {
        return context.employee.employeeNumber
      }

      return '-'
    },

    getName(context) {
      if (context.staffScheduling.type === StaffSchedulingType.Employee) {
        return `${context.employee.firstname} ${context.employee.lastname}`
      }

      if (
        context.staffScheduling.type === StaffSchedulingType.ServiceProvider
      ) {
        return context.staffScheduling.serviceProviderName
      }

      return null
    },

    getServiceProvider(context) {
      if (
        context.staffScheduling.type === StaffSchedulingType.ServiceProvider
      ) {
        return context.serviceProvider.name
      }

      return '-'
    },

    print() {
      window.print()
    },
  },
}
</script>

<style lang="scss">
#pdf-container {
  position: relative;
  background: #f1f1f1;

  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  .controls {
    position: fixed;
    bottom: 1.5rem;
    right: calc(1.5rem - 5px);

    display: flex;
    justify-content: space-between;
    gap: 1rem;

    padding: 1rem;

    border-radius: 7px;

    background: white;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  }

  .page {
    position: relative;

    width: 21cm;
    min-height: 29.7cm;

    padding: 20mm;

    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);

    background: white !important;
    color: black !important;

    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 14px;

    h2 {
      color: black !important;
    }

    table {
      table-layout: auto;
      width: 100%;
      border-collapse: collapse;

      margin-bottom: 3rem;

      td,
      th {
        border: 1px solid #ddd;
        padding: 8px;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      th {
        padding-block: 8px;
        padding-inline: 12px;
      }
    }
  }
}
</style>

<style lang="scss">
@media print {
  body,
  #pdf-container,
  .page {
    background: white !important;
    margin: 0 !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #pdf-container {
    min-height: 0;
    padding: 0 !important;
  }

  html,
  body {
    height: auto !important;
  }

  .controls {
    display: none;
  }
}

@page {
  size: A4;
  margin: 0;
}
</style>
